import React, { useCallback } from "react";
import { useRouter } from "next/router";
import { REQUEST_VISIT_BY_FAMILY } from "../../../../../../graphql/mutations/mutationEvent";
import { REQUEST_CARD } from "../../../../../../graphql/mutations/mutationCard";

import { useMutation } from "@apollo/client";

const origin = 2;

function PayByRequest({
  buttonTitle,
  finishFunc,
  clas = "",
  familyProfileId,
  onResponse,
  // card,
  eventIdDefault,
  options,
}) {
  const router = useRouter();
  const {
    query: { eventId, templateId },
  } = router;

  const event = eventIdDefault || eventId;

  const [requestVisit] = useMutation(REQUEST_VISIT_BY_FAMILY, {
    refetchQueries: [`ClientEventDetail`],
  });

  const [requestCard] = useMutation(REQUEST_CARD);

  const visitCallback = useCallback(() => {
    requestVisit({
      variables: {
        origin,
        eventId,
        ...(options?.length && { options: JSON.stringify(options) }),
        ...(familyProfileId && { familyProfileId }),
      },
    }).then(
      () => {
        finishFunc();
      },
      ({ graphQLErrors, message }) => {
        if (graphQLErrors?.[0]?.result == 5) return;

        finishFunc();
        onResponse({ isSuccess: false, message });
      }
    );
  }, [event, options, familyProfileId]);

  const cardCallback = useCallback(() => {
    requestCard({
      variables: {
        templateId,
        ...(familyProfileId && { familyProfileId }),
      },
    }).then(
      () => {
        finishFunc();
        onResponse({ isSuccess: true, message: "" });
      },
      ({ graphQLErrors, message }) => {
        if (graphQLErrors?.[0]?.result == 5) return;

        finishFunc();
        onResponse({ isSuccess: false, message });
      }
    );
  }, [templateId, familyProfileId]);

  return (
    <div
      className="payment-card__button-block"
      {...(clas && {
        style: { maxWidth: "none", paddingRight: "10px", paddingLeft: "10px" },
      })}
    >
      {!!event ? (
        <button className={clas ? clas : `payment-card__btn`} onClick={visitCallback} type="button">
          {buttonTitle}
        </button>
      ) : (
        !!templateId && (
          <button className={clas ? clas : `payment-card__btn`} onClick={cardCallback} type="button">
            {buttonTitle}
          </button>
        )
      )}
    </div>
  );
}

export default PayByRequest;
