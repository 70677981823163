import { gql } from "@apollo/client";

export const ACTIVATE_CARD = gql`
  mutation ActivateCard($cardId: Int!) {
    activateCard(cardId: $cardId) {
      card {
        id
      }
    }
  }
`;

export const FREEZE_CARD = gql`
  mutation FreezeCard($cardId: Int!) {
    freezeCard(cardId: $cardId) {
      card {
        id
        paused
      }
    }
  }
`;

export const UNFREEZE_CARD = gql`
  mutation UnfreezeCard($cardId: Int!) {
    unfreezeCard(cardId: $cardId) {
      card {
        id
        paused
      }
    }
  }
`;

export const CANCEL_CARD = gql`
  mutation CancelCardRequest($cardId: Int!) {
    cancelCardRequest(id: $cardId) {
      ok
    }
  }
`;

export const PROLONG_CARD = gql`
  mutation CardProlongationRequest($cardId: Int!) {
    cardProlongationRequest(cardId: $cardId) {
      card {
        id
      }
    }
  }
`;

export const REQUEST_CARD = gql`
  mutation RequestCard($templateId: Int!, $familyProfileId: Int) {
    requestCard(templateId: $templateId, familyProfileId: $familyProfileId) {
      card {
        id
      }
    }
  }
`;

export const CARD_CREATE_PAYMENT = gql`
  mutation CardCreatePayment(
    $templateId: Int!
    $familyProfileId: Int
    $promocode: String
    $nextUrl: String!
  ) {
    onlinePayment: cardCreatePayment(
      templateId: $templateId
      familyProfileId: $familyProfileId
      promocode:$promocode
      nextUrl: $nextUrl
    ) {
      result

      # card {
      #   id
      # }
      payment {
        action
        price
        priceOriginal
        priceCategory
        priceCategoryDetail
        comment
        clientCommission
        parameters
      }
    }
  }
`;

export const CARD_UPDATE_PAYMENT = gql`
  mutation CardUpdatePayment($cardId: Int!, $nextUrl: String!) {
    onlinePayment: cardUpdatePayment(cardId: $cardId, nextUrl: $nextUrl) {
      result
      payment {
        action
        price
        priceOriginal
        priceCategory
        priceCategoryDetail
        comment
        clientCommission
        parameters
      }
    }
  }
`;
